import React, { Fragment, useEffect, useState } from 'react';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import CustomAutocomp from '../components/customAutocomplete';
import Box from '@mui/material/Box';
import ClaimsReportForm from '../components/claimsReport-form';
import {  useParams } from 'react-router-dom';
import axios from 'axios';
import * as countries from '../common/countries';
import Grid from '@mui/material/Grid';
import LimitedAutocomplete from '../components/LimitedAutocomplete';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';

interface claimsreportProps  {
}

const ClaimsreportForm: React.FC<claimsreportProps> = () => {

  const API_URL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:5011';
  const [insurer, setInsurer] = useState(null);
  const [selectedCountry, setCountry] = useState('');
  const [policies, setPolicies] = useState([]);
  const [selectedPolicies, setSelectedPolicies] = useState([]);
  const [selectedContracts, setSelectedContracts] = useState([]);
  const [allPolicies, setAllPolicies] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [policiesByContracts, setPoliciesByContracts] = useState([]);
  const params = useParams();

  let result: any = [];

  const [contracts, setContracts] = useState([]);
  const [queryParam, setQueryParam] = useState(params.queryType);
  useEffect(() => {
    switch (queryParam) {

      case "1":
        setQueryParam("1");
        break;
      case "2":
        const fetchData = async () => {
          const envs = process.env;
          console.log("process.env====",process.env , envs, API_URL)
          const result = await axios(
            API_URL + '/contracts',
          );

          setContracts(result.data);
        };

        fetchData();
        setQueryParam("2");
        break;
      case "3":
        setQueryParam("3");

        break;
      default:
        break;
    }

  }, [queryParam, API_URL]);

 
  const [selected, setSelected] = React.useState(false);

  const handleCountryChange = (e: any, value: any, reason: any) => {

    switch (params.queryType) {

      case "1":
        if (value) {
          setCountry(value);
          axios.post(API_URL + '/policies/country', {
            dbCode: value.dbCode
          }).then((response) => {
            setPolicies(response.data)
          });
        }

        break;
      case "2":
        break;
      case "3":

        break;
      default:
        break;
    }
  };

  const handleInsurerChange = (e: any, v: any) => {
    console.log('tttttttttttttttttttttttttttttttttttttttt=', e, v)
    // axios.post(API_URL + '/insurers/policies', {
    //   dbCode: v.dbCode
    // }).then((response) => {
    //   setInsurer(response.data)
    // })
  };
  const handleAllPoliciesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAllPolicies(event.target.checked);
  };

  const handlePolicyHolderChange = (val: any) => {
    setAllPolicies(false);
    let pol: any[] = selectedPolicies;
    let contracts: any[] = selectedContracts;
    let polCont: any[] = policiesByContracts;
    console.log("inside the handlePolicyHolderChange===", val)
    if (val.length !== 0) {
      const contractIndex = contracts.findIndex((e, index)=> e.name === val[val.length - 1].name );
      if (contractIndex === -1){
        contracts.push({
          name: val[val.length - 1].name,
          value: val[val.length - 1].value
        });
        setSelectedContracts([...contracts as never[]])
        val.forEach((value: any) => {
          if (value.policies) {
            pol.push(...value.policies)
          }
        });
        polCont.push({
          contract: val[val.length - 1].value,
          policies: pol.map((pol: any) => pol['value'])
        })
        setPoliciesByContracts(polCont as never[])
        setSelectedPolicies(pol as never[]);
      }else{

        let tmpPol: any[] = [];
        let tmpPolCont: any[] = [];
        console.log("i m inside the thing====== AA", val, pol, polCont)
        setSelectedContracts([...val as never[]]);
        val.forEach((value: any) => {
          polCont.forEach((val)=>{
            console.log('inside the thing of',val, value)
            if(value.value === val.contract){
             const policies  = val.policies.map((po: any)=>({
                  name: po,
                  value: po
              }))
              console.log("les boules de noel", policies)
              tmpPol.push(...policies);
              tmpPolCont.push({
                contract: val.contract,
                policies: val.policies
              })
            }
          })
        });
        setPoliciesByContracts([...tmpPolCont as never[]])
        setSelectedPolicies([...tmpPol as never[]]);

        console.log("i m inside the thing======", val, pol, polCont, tmpPolCont,tmpPol )
      }

    } else {
      contracts = val;
      setPoliciesByContracts([])
      setSelectedPolicies([]);
      setSelectedContracts([])
    }



    console.log("inside the handlePolicyChange=========", val, policiesByContracts, selectedPolicies, selectedContracts)
  };
  return (
    <Fragment>

      {
        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, marginLeft: 'auto', marginRight: 'auto'}} >
          <FormControl component="fieldset">
            <Grid container component="form" >
              {queryParam !== "2" ?
                (<Grid item xs={6}>
                  <FormControl sx={{ m: 2 }} >
                    <CustomAutocomp id='insurerCountry' label='Pays' dataArray={countries.COUNTRIES} handleValueSelected={handleCountryChange} />
                    <FormHelperText id="outlined-weight-helper-text">Selectionner le Pays pour filtrer les Assureurs</FormHelperText>
                  </FormControl>
                </Grid>) : (<Grid item xs={6}>
                  <FormControl sx={{ m: 2 }} >
                    <LimitedAutocomplete id='policyholder' label='Souscripteurs'
                      options={contracts}
                      selectedOptions={selectedContracts}
                      setSelectedOptions={handlePolicyHolderChange}
                      maxSelections={3}
                      errorText=""
                    />
                    <FormHelperText id="outlined-weight-helper-text">Selectionner la liste des souscripteurs</FormHelperText>
                  </FormControl>
                </Grid>)}

              <Grid item xs={6}>
                {queryParam === "3" ?
                  (<FormControl sx={{ m: 2 }}>
                    <CustomAutocomp id='insurer' label='Assureur' dataArray={insurer} handleValueSelected={handleInsurerChange} />
                    <FormHelperText id="outlined-weight-helper-text">Assureur</FormHelperText>
                  </FormControl>) :
                  queryParam === "1" ?
                    (<FormControl sx={{ m: 2 }}>
                      <FormGroup>
                        <LimitedAutocomplete id='policies' label='Policies'
                          selectedOptions={selectedPolicies}
                          setSelectedOptions={setSelectedPolicies}
                          options={policies}
                          maxSelections={10}
                          errorText="" />
                        <FormHelperText id="outlined-weight-helper-text">Polices</FormHelperText>
                        <FormControlLabel control={<Checkbox checked={allPolicies} defaultChecked onChange={handleAllPoliciesChange} name="allPolicies" />} label="Pour toutes les polices?" />
                      </FormGroup>

                    </FormControl>) : (<></>)}

              </Grid>

              <Grid item xs={2}>
                { queryParam === "4" ?
                    (<FormGroup sx={{ m: 2 }}>  
                        <FormControlLabel control={<Checkbox defaultChecked />} label="All?" />
                    </FormGroup>) : (<></>)}
              </Grid>
            </Grid>
          </FormControl>
        </Box>
      }
      <ClaimsReportForm contractsData={contracts} queryParam={params.queryType} policiesGrouped={policiesByContracts} policies={selectedPolicies} country={selectedCountry} allPolicies={allPolicies} />
    </Fragment>
  )
}
export default ClaimsreportForm;