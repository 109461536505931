import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

const Road = styled(Link)({
    textDecoration: 'none',
});

export default function MediaControlCard({ menuData }: { menuData: any }) {
    return (
        <div >
            <Card sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}>
                <Box >
                    <CardContent sx={{ fontSize: 90 }} >
                        <InsertChartIcon fontSize="inherit" />
                        <Typography component="div" variant="h5">
                            {menuData.title}
                        </Typography>
                        <CardContent >
                            <Typography variant="body2" color="text.secondary" component="div">
                                {menuData.description}
                            </Typography>
                        </CardContent>
                    </CardContent>
                </Box>
                <Box sx={{ alignSelf: 'flex-end', alignContent: "flex-end", marginLeft: 'auto' }}>
                    <CardActions >
                        {menuData && menuData.back ? (
                            <Road to='/' >
                                <Button size="small">
                                    <ArrowBackIos sx={{ fontSize: 10 }} />  Retour
                                </Button>
                            </Road>
                        ) : (<></>)
                        }
                        {menuData && menuData.road ? (
                            menuData.queryType ? (
                               
                        <Road to={menuData.road + '/' + menuData.queryType} >
                            <Button size="small">
                                Accéder <ArrowForwardIosIcon sx={{ fontSize: 10 }} />
                            </Button>
                        </Road>
                        ) : (
                        <Road to={menuData.road} >
                            <Button size="small">
                                Accéder <ArrowForwardIosIcon sx={{ fontSize: 10 }} />
                            </Button>
                        </Road>)
                        ) : (<></>)
                        }
                    </CardActions>
                </Box>

            </Card>
        </div>
    );
}
