import React, { useState } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import './styles.css';
import Box from '@mui/material/Box/Box';
import FormControl from '@mui/material/FormControl/FormControl';
import InputAdornment from '@mui/material/InputAdornment/InputAdornment';
import InputLabel from '@mui/material/InputLabel/InputLabel';
import IconButton from '@mui/material/IconButton/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput/OutlinedInput';
import Button from '@mui/material/Button/Button';
import Stack from '@mui/material/Stack/Stack';
import { isEmailValid } from '../utils/groupBy';
import axios from 'axios';

interface Login {
  name: string;
  value: string;
}


interface Loginprops {
    token: string;
    username: string | any;
    password: string | any;
}


const Login = () => {

    const [showPassword, setShowPassword] = React.useState(false);
    const [valid, setValidity] = React.useState(false);
    const [token, setToken] = React.useState('');

    const [username, setUserName] = React.useState();

    const [password, setPassword] = React.useState('');


  async function loginUser(username: any, password : any) {

    axios.post('http://localhost:4000/login',{
    username,
    password
    }, {
        responseType: 'json'
      }).then(data => {
        
        console.log( data)})

    // return fetch('http://localhost:4000/login', {
   
    //   method: 'POST',
   
    //   headers: {
   
    //     'Content-Type': 'application/json'
   
    //   },
   
    //   body: JSON.stringify(credentials)
   
    // })
   
    //   .then(data => data.json())
   
   }
  
   const handleSubmit = async (e: any) => {
 
     e.preventDefault();
     loginUser(username, password)
    //  const token = await loginUser({
 
    //    username,
 
    //    password
 
    //  });
 
     console.log('==========>>>>>>>>>>>>>', username, password)
    //  setToken(token);
 
 }

    const onChange = ((event: any, val: any) => {
        if(event.target.id === "username"){
            if(isEmailValid(event.target.value)){
                setUserName(event.target.value)
                password!== '' ? setValidity(true) : setValidity(false)
            }else{
                setValidity(false)
            }
        }else{
            setPassword(event.target.value)
            setValidity(isEmailValid(username))
        }

    });

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    };

    return(

        <div className="container">
    
          <h1>Please Log In</h1>
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          <form onSubmit={handleSubmit}>
            <div>
          <FormControl sx={{ m: 2, width: '40ch' }} variant="outlined">
          <InputLabel htmlFor="outlined-username">Utilisateur</InputLabel>
          <OutlinedInput
            id="username"
            type='email'
            label="Utilisateur"
            onChange={e => onChange(e,e.target.value)}
          />
        </FormControl>
        </div>
        <div>
        <FormControl sx={{ m: 2, width: '40ch' }} variant="outlined">
          <InputLabel htmlFor="outlined-password">Password</InputLabel>
          <OutlinedInput
            id="password"
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            onChange={e => onChange(e,e.target.value)}
            label="Password"
          />
        </FormControl>
        </div>
    <div>
    <FormControl sx={{ m: 2, width: '40ch' }} variant="outlined">
        <Stack direction="row" spacing={2}>
            {
                valid ?<Button variant="outlined" onClick={handleSubmit}>CONNEXION</Button>: <Button variant="outlined" disabled>CONNEXION</Button>
            }
            <Button variant="outlined" disabled>
                MOT DE PASSE OUBLIE
            </Button>

        </Stack>
    </FormControl>
    </div>
    
          </form>
          </Box>
        </div>
    
      )


    }

    export default Login;